define("discourse/plugins/discourse-assign/discourse/controllers/group-assigned-show", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/controllers/user-topics-list", "discourse-common/config/environment", "discourse-common/lib/debounce"], function (_exports, _controller, _object, _computed, _service, _userTopicsList, _environment, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let GroupAssignedShow = _exports.default = (_dec = (0, _computed.alias)("currentUser.staff"), (_class = class GroupAssignedShow extends _userTopicsList.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "user", _descriptor3, this);
      _defineProperty(this, "queryParams", ["order", "ascending", "search"]);
      _defineProperty(this, "order", "");
      _defineProperty(this, "ascending", false);
      _defineProperty(this, "search", "");
      _defineProperty(this, "bulkSelectEnabled", false);
      _defineProperty(this, "selected", []);
      _initializerDefineProperty(this, "canBulkSelect", _descriptor4, this);
    }
    _setSearchTerm(searchTerm) {
      this.set("search", searchTerm);
      this.refreshModel();
    }
    refreshModel() {
      this.set("loading", true);
      this.store.findFiltered("topicList", {
        filter: this.model.filter,
        params: {
          order: this.order,
          ascending: this.ascending,
          search: this.search,
          direct: this.model.params.direct
        }
      }).then(result => this.set("model", result)).finally(() => {
        this.set("loading", false);
      });
    }
    async unassign(targetId) {
      let targetType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Topic";
      await this.taskActions.unassign(targetId, targetType);
      this.router.refresh();
    }
    reassign(topic) {
      this.taskActions.showAssignModal(topic, {
        onSuccess: () => this.router.refresh()
      });
    }
    changeSort(sortBy) {
      if (sortBy === this.order) {
        this.toggleProperty("ascending");
        this.refreshModel();
      } else {
        this.setProperties({
          order: sortBy,
          ascending: false
        });
        this.refreshModel();
      }
    }
    onChangeFilter(value) {
      (0, _debounce.default)(this, this._setSearchTerm, value, _environment.INPUT_DELAY * 2);
    }
    toggleBulkSelect() {
      this.toggleProperty("bulkSelectEnabled");
    }
    refresh() {
      this.refreshModel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_controller.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "canBulkSelect", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBulkSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class));
});