define("discourse/plugins/discourse-assign/discourse/routes/user-activity-assigned", ["exports", "discourse/lib/cookie", "discourse/routes/user-topic-list", "I18n"], function (_exports, _cookie, _userTopicList, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class UserActivityAssigned extends _userTopicList.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "templateName", "user-activity-assigned");
      _defineProperty(this, "controllerName", "user-activity-assigned");
      _defineProperty(this, "userActionType", 16);
      _defineProperty(this, "noContentHelpKey", "discourse_assigns.no_assigns");
    }
    beforeModel() {
      if (!this.currentUser) {
        (0, _cookie.default)("destination_url", window.location.href);
        this.transitionTo("login");
      }
    }
    model(params) {
      return this.store.findFiltered("topicList", {
        filter: `topics/messages-assigned/${this.modelFor("user").username_lower}`,
        params: {
          exclude_category_ids: [-1],
          order: params.order,
          ascending: params.ascending,
          search: params.search
        }
      });
    }
    titleToken() {
      return _I18n.default.t("discourse_assign.assigned");
    }
  }
  _exports.default = UserActivityAssigned;
});